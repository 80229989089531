import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { scroller } from 'react-scroll';

export default function useScrollTo() {
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.scrollTo) {
      scroller.scrollTo(state.scrollTo, { smooth: true });
    }
  }, [state]);
}
