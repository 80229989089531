import { Link as RouterLink } from 'react-router-dom';

import stepExploit from '../../images/step--exploit.svg';
import stepWebsite from '../../images/step--website.svg';
import stepPlay from '../../images/step--play.svg';

const Steps = () => (
  <section className="steps">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="steps__inner">
            <div className="steps__inner--step">
              <div>
                <img src={stepExploit} alt="Exploit" />
              </div>
              <div>
                <h3>Exploit motion analysis</h3>
                <p>
                  Anti-CAPTCHA bots are constantly evolving. Graboxy CAPTCHA
                  is the first to use movement analysis to verify humans.
                  Our cutting-edge machine learning technology can finally
                  be deployed in the fight against bots.
                </p>
              </div>
            </div>
            <div className="steps__inner--step">
              <div>
                <img src={stepWebsite} alt="Website" />
              </div>
              <div>
                <h3>Stop website abuse</h3>
                <p>
                  Attacks against your website are mostly automated.
                  Unexpected peaks in traffic are usually
                  caused by bot attacks. You can keep unwanted traffic
                  from your website by implementing a simple human vs.
                  bot detection.
                </p>
                <p>
                  CAPTCHAs are the most efficient way
                  to prevent bots from spamming.
                </p>
              </div>
            </div>
            <div className="steps__inner--step">
              <div>
                <img src={stepPlay} alt="Play" />
              </div>
              <div>
                <h3>Plug’n’Play</h3>
                <p>
                  Insert the Graboxy CAPTCHA JS code in your website
                  with our simple
                  {' '}
                  <RouterLink to="/integration">
                    step-by-step integration manual.
                  </RouterLink>
                </p>
                <p>
                  Graboxy CAPTCHA instantly delivers a result based
                  on the challenge. You decide what to do next
                  according to your own rules: require email verification
                  and 2FA, or block the session.
                </p>
              </div>
            </div>
            <div className="liner" />
          </div>
          <div className="col-12 conversion">
            <a
              href="https://example.graboxy.com"
              rel="noreferrer"
              target="_blank"
              className="btn"
            >
              Try Demo now
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Steps;
