import ReactMarkdown from 'react-markdown';
import useMarkdown from '../../hooks/useMarkdown';

import BackToHome from '../../components/shared/BackToHome';
import integration from './Integration.md';

function Integration() {
  const content = useMarkdown(integration);

  return (
    <section className="integration">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <BackToHome />
            <ReactMarkdown>
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Integration;
