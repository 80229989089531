import { useEffect, useState } from 'react';
import axios from 'axios';

import { WP_API_URL } from '../../constants';

export default function useCategories(slug) {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const params = new URLSearchParams();

    if (slug) {
      params.append('slug', slug);
    }

    axios
      .get(`${WP_API_URL}/categories?${params}`)
      .then((response) => setCategories(response.data))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [slug, setIsLoading, setCategories]);

  return {
    isLoading,
    categories,
  };
}
