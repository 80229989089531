import React from 'react';
import { Link } from 'react-router-dom';

import {
  Form as CustomForm,
  TextInput,
  SubmitButton,
} from 'custom-form-client/react';

import './Form.scss';

const Form = () => (
  <section className="submit" id="submit">
    <div className="container">
      <div className="row">
        <div className="col">
          <CustomForm
            id="form"
            className="form"
            type="contact"
            action={process.env.REACT_APP_CUSTOM_FORM_URL}
            responseMessage="Thank you for your message."
            captchaType="graboxyCaptcha"
            captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
            captchaOptions={{
              urlBase: process.env.REACT_APP_CAPTCHA_URL_BASE,
              apiKey: process.env.REACT_APP_GRABOXY_API_KEY,
              trapUrl: process.env.REACT_APP_TRAP_SERVER_URL,
            }}
          >
            <div className="form__title">
              <h2>
                <span className="white">Want to know</span>
                {' '}
                <span>more</span>
                {' '}
                <span>about</span>
                {' '}
                <span>Graboxy</span>
                {' '}
                <span>CAPTCHA?</span>
              </h2>
            </div>

            <div className="form__inner">
              <TextInput
                id="name"
                label="Name"
                className="form__inner--row"
                required
              />

              <TextInput
                id="email"
                type="email"
                label="E-mail"
                className="form__inner--row"
                required
              />

              <TextInput
                id="company"
                label="Company"
                className="form__inner--row"
              />

              <TextInput
                id="message"
                label="Message"
                className="form__inner--row"
                multiline
              />

              <div className="check text-start">
                <div>
                  <input
                    id="subscribe"
                    name="subscribe"
                    type="checkbox"
                  />
                  <label htmlFor="subscribe">
                    Send me news about product updates
                  </label>
                </div>

                <div>
                  <input
                    id="accept"
                    name="accept"
                    type="checkbox"
                    required
                  />
                  <label htmlFor="accept">
                    I agree to the
                    {' '}
                    <Link to="/privacy" target="_blank">
                      Privacy Policy
                    </Link>
                    <small>(Required)</small>
                  </label>
                </div>
              </div>

              <div className="submit-container">
                <SubmitButton className="btn">Send</SubmitButton>
              </div>
            </div>
          </CustomForm>
        </div>
      </div>
    </div>
  </section>
);

export default Form;
