const Privacy = () => (
  <section className="privacy">
    <div className="container">
      <h2>
        PRIVACY POLICY
      </h2>
      <span>
        Effective date: 2020-04-15
      </span>
      <h3>Introduction</h3>
      <p>
        Welcome to
        {' '}
        <a href="https://cursorinsight.com">Cursor Insight</a>
        .
      </p>
      <p>
        Cursor Insight Ltd., located at 22 Wenlock Road, London, N1 7GU, United
        Kingdom (“
        <em>Cursor Insight</em>
        ”) appreciate the interest you
        (“
        <em>you</em>
        ” or “
        <em>your</em>
        ”) have shown in our products and
        services by visiting one of our websites
        {' '}
        <a href="https://www.cursorinsight.com">www.cursorinsight.com</a>
        {' '}
        or
        {' '}
        <a href="https://www.graboxy.com">www.graboxy.com</a>
        {' '}
        (“
        <em>Websites</em>
        ”).
      </p>
      <p>
        This Privacy Policy outlines our guidelines for the information Cursor
        Insight collects when you interact with us, such as when you visit our
        Websites, or when you use the products and services offered by us.
      </p>
      <p>
        These terms apply to the Websites and any other Cursor Insight sites,
        services and products offered by Cursor Insight and subsidiaries insofar
        as they collect data and display these terms or include a link to it.
        Therefore, these terms are not applicable to Cursor Insight sites,
        products and services that have their own privacy statement or which do
        not include this privacy policy.
      </p>
      <h4>What is a Privacy policy?</h4>
      <p>
        The privacy policy sets the conditions under which Cursor Insight, is
        responsible for processing, collects and processes the personal data of
        all visitors to its Websites.
      </p>
      <p>
        Cursor Insight declares to respect the privacy of visitors and the
        protection of their personal data. As data controller, Cursor Insight
        complies with the regulations in force regarding the protection of
        personal data and particular the provisions of the amended law n°78617
        of 6 January 1978 and the regulation (EU) n°2016/679 of 27 April 2016 on
        the protection of individuals with regard to the processing of personal
        data (called “
        <em>GDPR</em>
        ”).
      </p>
      <p>
        Cursor Insight (“
        <em>us</em>
        ”, “
        <em>we</em>
        ”, or “
        <em>our</em>
        ”) operates
        {' '}
        <a href="https://cursorinsight.com">https://cursorinsight.com/</a>
        {' '}
        and
        {' '}
        <a href="https://graboxy.com">https://graboxy.com/</a>
        {' '}
        (hereinafter referred to as “
        <em>Service</em>
        ”).
      </p>
      <p>
        Our Privacy Policy governs your visit to
        {' '}
        <a href="https://cursorinsight.com">https://cursorinsight.com/</a>
        {' '}
        and
        {' '}
        <a href="https://graboxy.com">https://graboxy.com/</a>
        , and explains how we collect, safeguard and disclose information that
        results from your use of our Service.
      </p>
      <p>
        We use
        your data to provide and improve Service. By using Service, you agree to
        the collection and use of information in accordance with this policy.
      </p>
      <h3>Definitions</h3>
      <ul>
        <li>
          <strong>SERVICE</strong>
          {' '}
          means the
          {' '}
          <a href="https://cursorinsight.com">https://cursorinsight.com/</a>
          {' '}
          and
          {' '}
          <a href="https://graboxy.com">https://graboxy.com/</a>
          {' '}
          Websites
          operated by Cursor Insight.
        </li>
        <li>
          <strong>PERSONAL DATA</strong>
          {' '}
          means data about a living individual who
          can be identified from those data (or from those and other information
          either in our possession or likely to come into our possession).
        </li>
        <li>
          <strong>USAGE DATA</strong>
          {' '}
          is data collected automatically either
          generated by the use of Service or from Service infrastructure itself
          (for example, the duration of a page visit).
        </li>
        <li>
          <strong>COOKIES</strong>
          {' '}
          are small files stored on your device
          (computer or mobile device).
        </li>
        <li>
          <strong>DATA CONTROLLER</strong>
          {' '}
          means a natural or legal person who
          (either alone or jointly or in common with other persons) determines
          the purposes for which and the manner in which any personal data are,
          or are to be, processed. For the purpose of this Privacy Policy, we
          are a Data Controller of your data.
        </li>
        <li>
          <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong>
          {' '}
          means any
          natural or legal person who processes the data on behalf of the Data
          Controller. We may use the services of various Service Providers in
          order to process your data more effectively.
        </li>
        <li>
          <strong>DATA SUBJECT</strong>
          {' '}
          is any living individual who is the
          subject of Personal Data.
        </li>
        <li>
          <strong>THE USER</strong>
          {' '}
          is the individual using our Service. The User
          corresponds to the Data Subject, who is the subject of Personal Data.
        </li>
      </ul>
      <h3>Information Collection and Use</h3>
      <p>
        We collect several different types of information for various
        purposes to provide and improve our Service to you.
      </p>
      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (“
        <em>Personal Data</em>
        ”). Personally identifiable
        information may include, but is not limited to:
      </p>
      <ul>
        <li>
          <strong>Email address</strong>
          <br />
          E-mail Newsletters. We may offer e-mail newsletters from time to time
          on the Service. If you sign up to receive a newsletter from us, we
          collect your e-mail address.  We may also offer sign ups for
          additionallists, such as, but not limited to: promotional offers,
          interest registration for new product launch. If you sign up to
          receive update, we collect your e-mail address as well.
        </li>
        <li>
          <strong>First name</strong>
          {' '}
          and
          <strong>last name</strong>
        </li>
        <li>
          <strong>Phone number</strong>
        </li>
        <li>
          <strong>
            Address, Country, State, Province, ZIP/Postal code,
            City
          </strong>
        </li>
        <li>
          <strong>Cookies and Usage Data</strong>
          <br />
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you.
        </li>
      </ul>
      <h4>Usage Data</h4>
      <p>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access Service by or through any device
        (“
        <em>Usage Data</em>
        ”).
      </p>
      <p>
        This Usage Data may include information such as your computer’s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When you access Service with a device, this Usage Data may include
        information such as the type of device you use, your device unique ID,
        the IP address of your device, your device operating system, the type of
        Internet browser you use, unique device identifiers and other diagnostic
        data.
      </p>
      <h4>Tracking Cookies Data</h4>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </p>
      <p>
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyze our Service.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>
      <p>
        Examples of Cookies we use:
      </p>
      <ul>
        <li>
          <strong>Session Cookies</strong>
          : We use Session Cookies to operate our Service.
        </li>
        <li>
          <strong>Preference Cookies</strong>
          : We use Preference Cookies to
          remember your preferences and various settings.
        </li>
        <li>
          <strong>Security Cookies</strong>
          : We use Security Cookies for security
          purposes.
        </li>
        <li>
          <strong>Advertising Cookies</strong>
          : Advertising Cookies are used to
          serve you with advertisements that may be relevant to you and your
          interests.
        </li>
      </ul>
      <h3>Use of Data</h3>
      <p>
        Cursor Insight uses the collected data for
        various purposes:
      </p>
      <ol>
        <li>
          to provide and maintain our Service;
        </li>
        <li>
          to notify you about changes to our Service;
        </li>
        <li>
          to allow you to participate in interactive features of our Service
          when you choose to do so;
        </li>
        <li>
          to provide customer support;
        </li>
        <li>
          to gather analysis or valuable information so that we can improve our
          Service;
        </li>
        <li>
          to monitor the usage of our Service;
        </li>
        <li>
          to detect, prevent and address technical issues;
        </li>
        <li>
          to fulfil any other purpose for which you provide it;
        </li>
        <li>
          to carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection;
        </li>
        <li>
          to provide you with notices about your account and/or subscription,
          including expiration and renewal notices, email-instructions,
          etc.;
        </li>
        <li>
          to provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information;
        </li>
        <li>
          in any other way we may describe when you provide the information;
        </li>
        <li>
          to contact you, if you have applied for a job on our Websites;
        </li>
        <li>
          for any other purpose with your consent.
        </li>
      </ol>
      <h3>Retention of Data</h3>
      <p>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </p>
      <p>
        We will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period, except when this data
        is used to strengthen the security or to improve the functionality of
        our Service, or we are legally obligated to retain this data for longer
        time periods.
      </p>
      <h3>Transfer of Data</h3>
      <p>
        Your information, including Personal Data, may be transferred to – and
        maintained on – computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>
      <p>
        If you are located outside Hungary and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        Hungary and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        Cursor Insight will take all the steps reasonably necessary to ensure
        that your data is treated securely accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organisation
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <h3>Disclosure of Data</h3>
      <p>
        We may disclose personal information that we collect, or you provide:
      </p>
      <ol>
        <li>
          <strong>Business Transaction.</strong>
          <p>
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred.
          </p>
        </li>
        <li>
          <strong>Other cases.</strong>
          <p>We may disclose your information also:</p>
          <ol>
            <li>
              to our subsidiaries and affiliates;
            </li>
            <li>
              to contractors, service providers, and other third parties we use
              to support our business;
            </li>
            <li>
              to fulfill the purpose for which you provide it;
            </li>
            <li>
              for the purpose of including your company’s logo on our Websites;
            </li>
            <li>
              for any other purpose disclosed by us when you provide the
              information;
            </li>
            <li>
              with your consent in any other cases;
            </li>
            <li>
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others.
            </li>
          </ol>
        </li>
      </ol>
      <h3>Service Providers</h3>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service (“
        <em>Service Providers</em>
        ”), provide Service on our behalf,
        perform Service-related services or assist us in analysing how our
        Service is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <h3>Links to Other Sites</h3>
      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party’s site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h3>Children’s Privacy</h3>
      <p>
        Our Services are not intended for use by children under the age of 18
        (“
        <em>Child</em>
        ” or “
        <em>Children</em>
        ”).
      </p>
      <p>
        We do not knowingly collect personally identifiable information from
        Children under 18. If you become aware that a Child has provided us with
        Personal Data, please contact us. If we become aware that we have
        collected Personal Data from Children without verification of parental
        consent, we take steps to remove that information from our
        servers.
      </p>
      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update “effective date” at
        the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        by email:
        {' '}
        <a href="mailto:privacy@cursorinsight.com">privacy@cursorinsight.com</a>
        .
      </p>
    </div>
  </section>
);

export default Privacy;
