import React from 'react';

import { GRABOXY_URL } from '../../constants';
import useCategories from './useCategories';
import usePosts from './usePosts';
import Posts from './Posts';
import './Slider.scss';

const Blog = () => {
  const {
    isLoading: isCategoriesLoading,
    categories,
  } = useCategories('captcha');

  const { isLoading, posts } = usePosts(categories[0]);

  if (isCategoriesLoading || isLoading || posts.length === 0) {
    return <div style={{ minHeight: 860 }} />;
  }

  return (
    <section id="Blog" title="Our blog" className="Slider">
      <div className="container">
        <h2 className="title">Our Blog</h2>

        <Posts posts={posts} />

        <div className="row text-center mt-3">
          <div className="col-12">
            <a
              href={`${GRABOXY_URL}/blog/tag/captcha`}
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              More blog articles
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
