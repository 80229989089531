import { Link as RouterLink } from 'react-router-dom';
import Card from './Card';

import iconPrivacy from '../../images/icon--privacy.svg';
import iconSimple from '../../images/icon--simple.svg';
import iconEasy from '../../images/icon--easy.svg';
import iconStand from '../../images/icon--stand.svg';

const Use = () => (
  <section className="use" id="use">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="title">Why use Graboxy CAPTCHA</h2>
        </div>
      </div>
      <div className="row">
        <Card
          img={iconPrivacy}
        >
          <b>Privacy first.</b>
          {' '}
          - Graboxy CAPTCHA does not collect any
          personal information about your users.
        </Card>
        <Card
          img={iconEasy}
        >
          A
          {' '}
          <b>fun and easy</b>
          {' '}
          maze CAPTCHA. Frustrate bots, not your users.
        </Card>
        <Card
          img={iconStand}
        >
          <b>Stand out</b>
          {' '}
          from the crowd. Customize the CAPTCHA, embed your brand and
          blend it into your website.
        </Card>
        <Card
          img={iconSimple}
        >
          <RouterLink to="/integration">
            <b>Simple integration</b>
          </RouterLink>
          {' '}
          - even when replacing
          or combining with alternative CAPTCHAs.
        </Card>
      </div>
    </div>
  </section>
);

export default Use;
