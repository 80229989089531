import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Faq = () => (
  <section className="faq">
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="faq__inner">
            <h2 className="title">FAQ</h2>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h5>What is Graboxy Captcha and why is it different?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We built a Captcha challenge that identifies bots using
                    cursor movement analysis. It’s a completely new and very
                    efficient approach to verify human web traffic. Unlike most
                    other captcha challenges using blurry images or distorted
                    sounds, solving our maze is easy and fun.
                  </p>
                  <p>
                    Moreover, Graboxy Capthca is the first brandable captcha,
                    letting you use your own colors, images and brand elements.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h5>Is it really free?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Using Graboxy Capthca is currently free until at least
                    mid-2022. We plan to keep it free up to one million
                    captcha calls per month per domain. We’ll charge a
                    small amount for using customized captchas starting
                    from Q3 2022.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h5>How exactly do you identify human web traffic?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Our company specializes in human movement analysis.
                    Solving the maze and the way a user moves the cursor
                    while solving the maze provide enough data for our
                    evaluation engine to differentiate human behavior from bots.
                    That’s why we don’t torture users with blurry images and
                    impossibly difficult tasks.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h5>Can’t bots easily solve your maze challenge?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We built a completely new type of captcha, so there aren’t
                    any tested malicious algorithms designed to solve our maze
                    challenge. And the good news is that if there will be,
                    it will be not enough to simply solve the maze. Our AI
                    technology is all about understanding and differentiating
                    human and artificial movement while solving the maze.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <h5>Who is the team behind Graboxy?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Graboxy is the cybersecurity arm of
                    {' '}
                    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
                    <a
                      href="http://cursorinsight.com"
                      target="_blank"
                    >
                      Cursor Insight
                    </a>
                    ,
                    a technology startup based in the UK and Hungary.
                    We are experts in human motion analysis and we build
                    applications across banking, healthcare and cybersecurity,
                    all based on our multi award-winning movement analysis
                    technology.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <h5>
                    Can I use any images or logos in my
                    customized Graboxy Captcha?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    You may upload and use any image in the Graboxy Captcha
                    embedded in your website, provided that you don’t break any
                    laws and we don’t find your image offensive or
                    discriminatory. For more on this, see our usage
                    {' '}
                    <Link to="/terms" target="_blank">
                      Terms and Conditions
                    </Link>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <h5>
                    Can I use other shapes or challenges than your circular
                    maze?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Currently, we allow users to customize the colors and
                    images used in our maze challenges. We are working on
                    adding even more flexibility to further customize the
                    captcha challenge. Get in touch, if you have a specific
                    need, and we might be able to adjust our updates
                    in the future.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <h5>
                    What platforms does your solution work on and are
                    there any limitations?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Graboxy Captcha supports the most popular desktop browsers
                    on Windows 10 and Mac. See our
                    {' '}
                    <Link
                      to="/integration"
                      target="_blank"
                    >
                      Integration Guide
                    </Link>
                    {' '}
                    for more details.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <h5>How do I embed Graboxy Captcha in my website?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We have a comprehensive Integration Guide
                    {' '}
                    <Link
                      to="/integration"
                      target="_blank"
                    >
                      here
                    </Link>
                    .
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <h5>Do you use cookies or other methods to track users?</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We value data protection and user privacy. We don’t collect
                    any personal information about users and we don’t
                    follow them across other websites or applications.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <h5>
                    How do I report a problem if something is not working,
                    crashed or is too slow?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Please
                    {' '}
                    <a href="mailto:captcha@graboxy.com">
                      email
                    </a>
                    {' '}
                    us with sufficient information about the issue
                    and we’ll try to help. Note that Graboxy Capthca is
                    currently in beta mode, so we provide support on a
                    best effort basis.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Faq;
