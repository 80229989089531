import React, { useEffect } from 'react';
import './App.scss';
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter,
} from 'react-router-dom';
import Reveal from 'react-reveal/Reveal';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import Cookie from './pages/cookie/Cookie';
import Integration from './pages/integration/Integration';
import IndexPage from './pages/IndexPage';

import ComingSoonHeader from './components/coming-soon-header/ComingSoonHeader';
import ComingSoonPage from './pages/ComingSoonPage';
import FormPage from './pages/FormPage';

// eslint-disable-next-line no-underscore-dangle
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const ComingSoonApp = () => (
  <Router>
    <main>
      <ComingSoonHeader />
      <Switch>
        <Route exact path="/" component={ComingSoonPage} />
        <Route exact path="/privacy">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Privacy />
            </Reveal>
          </ScrollToTop>
        </Route>
        <Route exact path="/terms">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Terms />
            </Reveal>
          </ScrollToTop>
        </Route>
        <Route exact path="/cookie">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Cookie />
            </Reveal>
          </ScrollToTop>
        </Route>
      </Switch>
    </main>
  </Router>
);

const IndexApp = () => (
  <Router>
    <main>
      <Header />
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/integration">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Integration />
            </Reveal>
          </ScrollToTop>
        </Route>
        <Route exact path="/privacy">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Privacy />
            </Reveal>
          </ScrollToTop>
        </Route>
        <Route exact path="/terms">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Terms />
            </Reveal>
          </ScrollToTop>
        </Route>
        <Route exact path="/cookie">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <Cookie />
            </Reveal>
          </ScrollToTop>
        </Route>
        <Route exact path="/formpage">
          <ScrollToTop>
            <Reveal effect="fadeInUp">
              <FormPage />
            </Reveal>
          </ScrollToTop>
        </Route>
      </Switch>
      <Footer />
    </main>
  </Router>
);

const App = process.env.REACT_APP_COMING_SOON_APP_ONLY === 'true'
  ? ComingSoonApp
  : IndexApp;

export default App;
