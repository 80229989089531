import BackToHome from '../../components/shared/BackToHome';

const urlMac = 'https://support.apple.com/kb/ph19214?locale=en_US';
const urlChrome = 'https://support.google.com/chrome/answer/95647';
const urlEdge = `https://privacy.microsoft.com/en-us/
windows-10-microsoft-edge-and-privacy`;
const urlExplorer = `https://support.microsoft.com/en-gb/help/17442/
windows-internet-explorer-delete-manage-cookies`;
const urlFirefox = `https://support.mozilla.org/en-US/kb/
enable-and-disable-cookies-website-preferences`;
const urlOpera = 'www.opera.com/help/tutorials/security/cookies/';
const urlAndroid = `https://support.google.com/chrome/answer/
95647?co=GENIE.Platform=Android&hl=en&oco=1`;
const urlBlackberry = `https://help.blackberry.com/en/blackberry-classic/
10.3.1/help/mwa1334238823957.html`;
const urlIphone = `https://support.google.com/chrome/answer/
95647?co=GENIE.Platform=iOS&hl=en&oco=1`;
const urlSafari = `https://support.google.com/chrome/answer/
95647?co=GENIE.Platform=Android&hl=en&oco=1`;

const Cookie = () => (
  <section className="privacy">
    <div className="container">
      <BackToHome />
      <h2>
        COOKIE POLICY
        <span>
          Last updated 2021-11-08
        </span>
      </h2>
      <h3>Introduction</h3>
      <p>
        <a href="https://cursorinsight.com">Cursor Insight Ltd.</a>
        {' '}
        (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels,
        and other tracking technologies when you visit our website (
        <a href="https://captcha.graboxy.com/">
          https://captcha.graboxy.com/
        </a>
        ), including any other media form,
        media channel, mobile website, or mobile application related
        or connected thereto (collectively, the “Site”) to help customize
        the Site and improve your experience.
      </p>
      <p>
        We reserve the right to make changes to this Cookie Policy at
        any time and for any reason. We will alert you about any changes
        by updating the “Last Updated” date of this Cookie Policy.
        Any changes or modifications will be effective immediately
        upon posting the updated Cookie Policy on the Site, and you waive
        the right to receive specific notice of each such
        change or modification.
      </p>
      <p>
        You are encouraged to periodically review this Cookie Policy
        to stay informed of updates. You will be deemed to have been made
        aware of, will be subject to, and will be deemed to have accepted
        the changes in any revised Cookie Policy by your continued use of
        the Site after the date such revised Cookie Policy is posted.
      </p>
      <h3>Use of Cookies</h3>
      <p>
        A “cookie” is a string of information which assigns you a
        unique identifier that we store on your computer.
        Your browser then provides that unique identifier to use
        each time you submit a query to the Site. We use cookies on
        the Site to, among other things, keep track of services you have
        used, record registration information, record your user preferences,
        keep you logged into the Site, facilitate purchase procedures,
        and track the pages you visit. Cookies help us understand how
        the Site is being used and improve your user experience.
      </p>
      <h3>Types of Cookies</h3>
      <p>
        The following types of cookies may be used when you visit the Site:
      </p>
      <h5><b>Advertising Cookies</b></h5>
      <p>
        Advertising cookies are placed on your computer by advertisers and
        ad servers in order to display advertisements that are most likely
        to be of interest to you. These cookies allow advertisers and ad
        servers to gather information about your visits to the Site and other
        websites, alternate the ads sent to a specific computer, and track
        how often an ad has been viewed and by whom. These cookies are linked
        to a computer and do not gather any personal information about you.
      </p>
      <h5><b>Analytics Cookies</b></h5>
      <p>
        Analytics cookies monitor how users reached the Site, and how they
        interact with and move around once on the Site. These cookies
        let us know what features on the Site are working the best and
        what features on the Site can be improved.
      </p>
      <h5><b>Our Cookies</b></h5>
      <p>
        Our cookies are “first-party cookies”, and can be either permanent
        or temporary. These are necessary cookies, without which the
        Site won&apos;t work properly or be able to provide certain features
        and functionalities. Some of these may be manually disabled in
        your browser, but may affect the functionality of the Site.
      </p>
      <h5><b>Personalization Cookies</b></h5>
      <p>
        Personalization cookies are used to recognize repeat visitors
        to the Site. We use these cookies to record your browsing history,
        the pages you have visited, and your settings and preferences
        each time you visit the Site.
      </p>
      <h5><b>Security Cookies</b></h5>
      <p>
        Security cookies help identify and prevent security risks.
        We use these cookies to authenticate users and protect user
        data from unauthorized parties.
      </p>
      <h5><b>Site Management Cookies</b></h5>
      <p>
        Site management cookies are used to maintain your identity or
        session on the Site so that you are not logged off unexpectedly,
        and any information you enter is retained from page to page.
        These cookies cannot be turned off individually, but you can
        disable all cookies in your browser.
      </p>
      <h5><b>Third-Party Cookies</b></h5>
      <p>
        Third-party cookies may be place on your computer when you visit
        the Site by companies that run certain services we offer.
        These cookies allow the third parties to gather and track certain
        information about you. These cookies can be manually disabled
        in your browser.
      </p>
      <h3>Control of Cookies</h3>
      <p>
        Most browsers are set to accept cookies by default. However,
        you can remove or reject cookies in your browser’s settings.
        Please be aware that such action could affect the availability
        and functionality of the Site.
      </p>
      <p>
        For more information on how to control cookies, check your browser
        or device’s settings for how you can control or reject cookies,
        or visit the following links:
      </p>
      <p>
        <a
          href={urlMac}
          target="_blank"
          rel="noreferrer"
        >
          Apple Safari
        </a>
      </p>
      <p>
        <a
          href={urlChrome}
          target="_blank"
          rel="noreferrer"
        >
          Google Chrome
        </a>
      </p>
      <p>
        <a
          href={urlEdge}
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Edge
        </a>
      </p>
      <p>
        <a
          href={urlExplorer}
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Internet Explorer
        </a>
      </p>
      <p>
        <a
          href={urlFirefox}
          target="_blank"
          rel="noreferrer"
        >
          Mozilla Firefox
        </a>
      </p>
      <p>
        <a
          href={urlOpera}
          target="_blank"
          rel="noreferrer"
        >
          Opera
        </a>
      </p>
      <p>
        <a
          href={urlAndroid}
          target="_blank"
          rel="noreferrer"
        >
          Android (Chrome)
        </a>
      </p>
      <p>
        <a
          href={urlBlackberry}
          target="_blank"
          rel="noreferrer"
        >
          Blackberry
        </a>
      </p>
      <p>
        <a
          href={urlIphone}
          target="_blank"
          rel="noreferrer"
        >
          Iphone or Ipad (Chrome)
        </a>
      </p>
      <p>
        <a
          href={urlSafari}
          target="_blank"
          rel="noreferrer"
        >
          Iphone or Ipad (Safari)
        </a>
      </p>
      <p>
        In addition, you may opt-out of some third-party cookies through the
        {' '}
        <a href="optout.networkadvertising.org/#!/">
          Network Advertising Initiative’s Opt-Out Tool
        </a>
        .
      </p>
      <h3>Other tracking technologies</h3>
      <p>
        In addition to cookies, we may use web beacons, pixel tags,
        and other tracking technologies on the Site to help customize
        the Site and improve your experience. A “web beacon” or
        “pixel tag” is tiny object or image embedded in a web page or email.
        They are used to track the number of users who have visited
        particular pages and viewed emails, and acquire other statistical data.
        They collect only a limited set of data, such as a cookie number,
        time and date of page or email view, and a description of the page
        or email on which they reside. Web beacons and pixel tags cannot
        be declined. However, you can limit their use by controlling the
        cookies that interact with them.
      </p>
      <h3>Contact us</h3>
      <p>
        If you have questions or comments about this Cookie Policy,
        please contact us at
        {' '}
        <a href="mailto:privacy@cursorinsight.com">
          privacy@cursorinsight.com
        </a>
        .
      </p>
    </div>
  </section>
);

export default Cookie;
