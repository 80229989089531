import ReactMarkdown from 'react-markdown';

import useMarkdown from '../../hooks/useMarkdown';
import terms from './Terms.md';

function Terms() {
  const content = useMarkdown(terms);

  return (
    <section className="privacy">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ReactMarkdown
              components={{
                h1: 'h2',
                em: 'span',
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
