import { Link } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import logo from '../../images/inverse-logo-graboxy-captcha.svg';

const Logo = () => (
  useLocation().pathname === '/' ? <ScrollLogo /> : <NavigateLogo />
);

const ScrollLogo = () => (
  <Link to="hero" spy smooth>
    <img src={logo} alt="logo" />
    <span className="beta-badge">BETA</span>
  </Link>
);

const NavigateLogo = () => (
  <RouterLink to="/">
    <img src={logo} alt="logo" />
    <span className="beta-badge">BETA</span>
  </RouterLink>
);

export default Logo;
