import { Link as RouterLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

import ScrollLink from '../scroll-link/ScrollLink';
import Logo from './Logo';

import logosentinel from '../../images/inverse-logo-graboxy-sentinel.svg';
import logo2fa from '../../images/inverse-logo-graboxy-2fa.svg';
import logoanticheat from '../../images/inverse-logo-graboxy-anticheat.svg';
import logocaptcha from '../../images/inverse-logo-graboxy-captcha.svg';
import logocursor from '../../images/inverse-logo-cursor-insight.svg';

const Header = () => (
  <header>
    <div id="menuToggle" className="only-desktop">
      <input type="checkbox" />
      <span />
      <span />
      <span />
      <ul id="menu">
        <h3>Graboxy Products</h3>
        <li>
          <a
            href="https://sentinel.graboxy.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logosentinel} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://2fa.graboxy.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo2fa} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://anti-cheat.graboxy.com/#/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logoanticheat} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://captcha.graboxy.com/#/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logocaptcha} alt="" />
          </a>
        </li>
        <h3>Company Website</h3>
        <li>
          <a
            href="https://cursorinsight.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logocursor} alt="Cursor Insight logo" />
          </a>
        </li>
      </ul>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-10">
          <Navbar expand="lg">
            <div className="logo">
              <Logo />
            </div>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav>
                <ul>
                  <li>
                    <RouterLink to="/integration">Integration</RouterLink>
                  </li>
                  <li>
                    <ScrollLink to="/" scrollTo="submit" smooth>
                      Contact
                    </ScrollLink>
                  </li>
                  <li>
                    <a href="https://admin.graboxy.com/login">
                      Sign In
                    </a>
                  </li>
                  <li className="only-mobile mt-4">
                    <a
                      href="https://admin.graboxy.com/registration"
                      rel="noreferrer"
                      target="_blank"
                      className="btn"
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>
              </Nav>
              <ul className="product-nav only-mobile">
                <h3>Graboxy Products</h3>
                <li>
                  <a
                    href="https://sentinel.graboxy.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logosentinel} alt="Graboxy Sentinel logo" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://2fa.graboxy.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logo2fa} alt="Graboxy 2FA logo" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://anti-cheat.graboxy.com/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logoanticheat} alt="Graboxy Anti-Cheat logo" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://captcha.graboxy.com/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logocaptcha} alt="Graboxy CAPTCHA logo" />
                  </a>
                </li>
                <h3 className="mt-1">Company Website</h3>
                <li>
                  <a
                    href="https://cursorinsight.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logocursor} alt="Cursor Insight logo" />
                  </a>
                </li>
              </ul>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="col-lg-2 only-desktop">
          <a
            href="https://admin.graboxy.com/registration"
            rel="noreferrer"
            target="_blank"
            className="btn"
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
