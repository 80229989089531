import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Slider from 'react-slick';
import mazeVideoMp4 from '../../videos/record.mp4';
import mazeCola from '../../images/maze--cola.png';
import mazeManchester from '../../images/maze--manchester.png';
import mazeToyota from '../../images/maze--toyota.png';
import mazeLogo from '../../images/maze--logo.png';

const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="hero" id="hero">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="hero__top">
              <h1>The world’s first brandable captcha</h1>
            </div>
            <div
              className="
                col-xl-4
                col-lg-5
                offset-lg-1
                slider-container
                only-mobile"
            >
              <Slider className="slider" {...settings}>
                <div>
                  <img src={mazeCola} alt="Maze Cola" />
                </div>
                <div>
                  <img src={mazeManchester} alt="Maze Manchester" />
                </div>
                <div>
                  <img src={mazeToyota} alt="Maze Toyota" />
                </div>
                <div>
                  <img src={mazeLogo} alt="Maze Logo" />
                </div>
              </Slider>
              <h5>Upload your logo or brand elements</h5>
            </div>
            <div className="hero__bottom">
              <h5>
                Replace fire hydrants and traffic lights with your brand.
              </h5>
              <p>
                Solve a simple maze and we’ll know whether you are a human.
                Graboxy CAPTCHA lets you boost your brand and make CAPTCHAs
                fun for users.
              </p>
              <p>Free for up to 1 million monthly requests.</p>
              <a
                href="https://example.graboxy.com"
                rel="noreferrer"
                target="_blank"
                className="btn"
              >
                Try Demo Now
              </a>
            </div>
          </div>
          <div className="col-xl-4 video-container">
            { /* eslint-disable max-len */}
            { /* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://www.producthunt.com/posts/graboxy-maze-captcha?utm_source=badge-review&utm_medium=badge&utm_souce=badge-graboxy-maze-captcha#discussion-body" target="_blank" className="producthunt-badge">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=321325&theme=light" alt="Graboxy maze CAPTCHA - Brandable captcha that is fun & easy to solve | Product Hunt" />
            </a>
            { /* eslint-enable max-len */}
            <video className="video" autoPlay muted loop>
              <source src={mazeVideoMp4} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </div>
          <div
            className="
              col-xl-4
              col-lg-5
              offset-lg-1
              slider-container
              only-desktop"
          >
            <Slider className="slider" {...settings}>
              <div>
                <img src={mazeCola} alt="Maze Cola" />
              </div>
              <div>
                <img src={mazeManchester} alt="Maze Manchester" />
              </div>
              <div>
                <img src={mazeToyota} alt="Maze Toyota" />
              </div>
              <div>
                <img src={mazeLogo} alt="Maze Logo" />
              </div>
            </Slider>
            <h5>Upload your logo or brand elements</h5>
          </div>
        </div>
        <div className="row">
          <Link
            to="use"
            smooth
            offset={-100}
            className="scroll"
          >
            <div className="scroll-downs">
              <div className="mousey">
                <div className="scroller" />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Consent"
        cookieName="Graboxy Captcha Landing"
        style={{ background: '#442458' }}
        buttonStyle={{
          background: '#C766F4',
          border: '0',
          borderRadius: '999px',
          boxShadow: 'none',
          color: '#fff',
          cursor: 'pointer',
          flex: '0 0 auto',
          padding: '10px 25px',
          margin: '15px',
        }}
        expires={150}
      >
        This website uses
        {' '}
        <RouterLink to="/cookie">
          cookies
        </RouterLink>
        {' '}
        to enhance the user experience.
      </CookieConsent>
    </section>
  );
};

export default Hero;
