import moment from 'moment';

import { GRABOXY_URL } from '../../constants';

function formatDate(dateStr) {
  const date = moment(dateStr);
  const today = moment();

  if (!date.isBefore(today.subtract(7, 'days'))) {
    return date.fromNow();
  }

  return date.format('DD/MM/YYYY');
}

function getAuthor(embedded) {
  const { author } = embedded;

  if (!author || !author[0]) {
    return undefined;
  }

  return {
    id: author[0],
    name: author[0].name,
    slug: author[0].slug,
    avatar: author[0].avatar_urls ? author[0].avatar_urls['96'] : undefined,
  };
}

function getFeaturedImage(embedded) {
  const featureMedia = embedded['wp:featuredmedia'];

  if (!featureMedia || !featureMedia[0]) {
    return undefined;
  }

  return featureMedia[0].source_url;
}

export function getTags(embedded) {
  const term = embedded['wp:term'];

  if (!term || !term[1]) {
    return [];
  }

  return term[1].map((tag) => ({
    id: tag.id,
    link: `${GRABOXY_URL}/blog/tag/${tag.slug}`,
    slug: tag.slug,
    name: tag.name,
  }));
}

export default function formatPosts(posts) {
  return posts.map(
    ({
      id, slug, title, date, content, excerpt, _embedded,
    }) => ({
      id,
      title,
      content,
      excerpt,
      link: `${GRABOXY_URL}/post/${id}/${slug}`,
      date: formatDate(date),
      author: getAuthor(_embedded),
      featuredImage: getFeaturedImage(_embedded),
      tags: getTags(_embedded),
    }),
  );
}
