import Reveal from 'react-reveal/Reveal';

import useScrollTo from '../hooks/useScrollTo';
import Hero from '../components/hero/Hero';
import Steps from '../components/steps/Steps';
import Blog from '../components/blog';
import Use from '../components/use/Use';
import Faq from '../components/faq/Faq';
import Form from '../components/form/Form';

const IndexPage = () => {
  useScrollTo();

  return (
    <div>
      <Reveal effect="fadeInUp">
        <Hero />
      </Reveal>
      <Reveal effect="fadeInUp">
        <Use />
      </Reveal>
      <Reveal effect="fadeInUp">
        <Steps />
      </Reveal>
      <Reveal effect="fadeInUp">
        <Blog />
      </Reveal>
      <Reveal effect="fadeInUp">
        <Faq />
      </Reveal>
      <Reveal effect="fadeInUp">
        <Form />
      </Reveal>
    </div>
  );
};

export default IndexPage;
