import React from 'react';
import PropTypes from 'prop-types';

import styles from './Badge.module.scss';

const Badge = ({ link, name }) => (
  <a href={link} className={styles.Badge}>
    {name}
  </a>
);

Badge.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Badge;
