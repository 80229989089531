import PropTypes from 'prop-types';

const Card = (props) => {
  const { img, children } = props;

  return (
    <div className="col-lg-6 mb-4">
      <div className="use__item">
        <img src={img} alt="boxIcon" />
        <p>
          {children}
        </p>
      </div>
    </div>
  );
};

Card.propTypes = {
  img: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Card;
