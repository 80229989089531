import { useEffect, useState } from 'react';
import axios from 'axios';

import { WP_API_URL } from '../../constants';
import formatPosts from './formatPosts';

export default function usePosts(category) {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const params = new URLSearchParams();
    params.append('_embed', '');
    params.append('per_page', '3');

    if (category) {
      params.append('categories', category.id);
    }

    axios.get(`${WP_API_URL}/posts?${params}`)
      .then((response) => setPosts(response.data))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [category, setIsLoading, setPosts]);

  return {
    isLoading,
    posts: formatPosts(posts),
  };
}
