import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 footer__left">
          <Link to="/privacy" target="_blank" className="me-4">
            Privacy Policy
          </Link>
          <Link to="/terms" target="_blank">
            Terms and Conditions
          </Link>
        </div>
        <div className="col-lg-6 footer__right">
          <p>
            © 2023,
            <a
              href="https://cursorinsight.com"
              target="_blank"
              rel="noreferrer"
            >
              Cursor Insight Ltd.
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
